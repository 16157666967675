import { render } from "./User.vue?vue&type=template&id=2128e346"
import script from "./User.ts?vue&type=script&lang=ts"
export * from "./User.ts?vue&type=script&lang=ts"

import "./user.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2128e346"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2128e346', script)) {
    api.reload('2128e346', script)
  }
  
  module.hot.accept("./User.vue?vue&type=template&id=2128e346", () => {
    api.rerender('2128e346', render)
  })

}

script.__file = "src/views/Catalog/components/User/User.vue"

export default script